
.modelPreview {
  position: relative;

  height: calc(100% - 37px);
  border: 1px solid #E2E9F0;
  border-radius: 10px;
  width: 100%;
  font-size: 0.875rem;
  font-family: 'Karla', sans-serif;
  color: #181F47;
  padding: 23px 14px 23px 14px;

  display: flex;
  flex-direction: column;
}

.modelPreview-title {
  text-transform: uppercase;
}

.modelPreview-title span:first-child {
  font-weight: bold;
}

.modelPreview .selectedPartType {
  font-weight: 700;
}

.modelPreview-svg {
  flex-grow: 1;
  position: relative;
}

.modelPreview .react-transform-wrapper {
  margin-top: 5%;
  margin-left: 5%;
  width: 90%;
  height: 90%;
}

.modelPreview .react-transform-component {
  width: 100%;
  height: 100%;
}

.modelPreview-canvas {
  flex-grow: 1;
}

.modelPreview .switchContainer {
  position: absolute;
  top: 23px;
  right: 14px;
  display: flex;
}

.modelPreview .generalButton:disabled {
  background-color: var(--secondary-color-light);
  cursor: inherit;
}
