#hamburgerMenu {
  height: 100vh;
  width: 100vw;
  background-color: #fbfbfb;
  position: fixed;
  top: 0;
  z-index: 200;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
#hamburgerMenu .hamburgerHeaderWrapper {
  height: 75px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 19px;
  align-items: center;
}
#hamburgerMenu .hamburgerMenu-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  color: var(--primary-color);
  padding: 27px 20px 15px 20px;
}
#hamburgerMenu .first-title {
  padding-left: 0;
}

#hamburgerMenu .hamburgerMenu-link,
#hamburgerMenu select {
  border: none;
  font-size: 1rem;
  font-family: "Karla", sans-serif;
  font-weight: 700;
  color: var(--primary-color);
  border-top: 1px solid #8a8ea2;
  text-decoration: none;
  padding: 15px 38px 15px 38px;
  cursor: pointer;
}
#hamburgerMenu .hamburgerMenu-lastLink,
#hamburgerMenu select {
  border-bottom: 1px solid #8a8ea2;
}
#hamburgerMenu .hamburgerButton {
  border: none;
  background: none;
  display: flex;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  width: 30px;
  height: 21px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
#hamburgerMenu .hamburgerButton .hamburgerLine {
  width: 100%;
  height: 3px;
  border-radius: 1.5px;
  background-color: var(--primary-color);
}
