@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap");

.header {
  width: 100%;
  flex-basis: 75px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: var(--primary-color);
  color: #8a8ea2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 40px 0 37px;
  z-index: 2;
}
#headerLogoWicam {
  max-height: 69px;
  width: auto;
  transform: translate(0, 5px);
}
.topNavigation {
  height: 75px;
  flex-grow: 1;
  overflow: hidden;
  padding: 0 0 0 20px;
}
.topNavigation ul {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
}
.topNavigation ul li {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  height: 100%;
  flex-grow: 1;
  padding: 0 20px;
  min-width: 0;
}
.topNavigation ul li a {
  margin: 0 -20px;
  padding: 0 20px;
  color: #8a8ea2;
  text-decoration: none;
  font-family: "Karla", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.topNavigation ul li a > span {
  display: contents;
}

.topNavigation ul li a:hover {
  background-color: var(--secondary-color);
  color: #fdfdfd;
}

.topNavigation ul li a svg {
  flex-shrink: 0;
}

.topNavigation ul li a span {
  overflow: hidden;
  text-overflow: ellipsis;
}

#svgUser {
  width: 30px;
  margin: 0 11px 0 0;
}
.svgUser-1 {
  fill: var(--secondary-color);
}
.svgUser-2 {
  fill: #fdfdfd;
}

#svgLogout {
  width: 24px;
}
.svgLogout-1 {
  fill: #8a8ea2;
}
#logoutButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.header .headerContainer {
  display: flex;
  align-items: center;
  font-family: "Karla", sans-serif;
  font-size: 1.25rem;
  color: #fdfdfd;
  height: 100%;
}

.header .headerContainer .langSelector {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FDFDFD%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  padding: 0 17px 0 0;
  background-repeat: no-repeat, repeat;
  background-position: right 0 top 50%, 0 0;
  background-size: 11px auto, 100%;
  border: none;
  color: #fdfdfd;
  margin: 0 32px 0 16px;
}
.header .headerContainer .langSelector option {
  padding: 20px;
  color: #000000;
}

.header .headerContainer .userContainer {
  display: flex;
  align-items: center;
  padding: 0 16px;
}
.header .headerContainer .userContainerDropdownWrapper:hover #userHover {
  display: flex;
}
.header .headerContainer .userContainerDropdownWrapper:hover .userContainer {
  background-color: var(--secondary-color);
  color: #fdfdfd;
}
.userContainerDropdownWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  height: 100%;
}

.header .headerContainer .userContainer span {
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header .hamburgerButton {
  display: none;
  border: none;
  background: none;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  width: 30px;
  height: 21px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.header .hamburgerButton .hamburgerLine {
  width: 100%;
  height: 3px;
  border-radius: 1.5px;
  background-color: #fbfbfb;
}

@media only screen and (max-width: 1470px) {
  .header .headerContainer .userContainer span {
    max-width: 120px;
  }
}

@media only screen and (max-width: 1370px) {
  .header .headerContainer .userContainer span {
    max-width: 80px;
  }
}

@media only screen and (max-width: 1280px) {
  .header {
    padding: 0 25px 0 37px;
  }
  .header .headerContainer .userContainer span {
    display: none;
  }
  #userHover {
    width: auto;
  }
  #svgUser {
    margin: 0 0 0 0;
  }
}

@media only screen and (max-width: 1085px) {
  .header {
    padding: 0 33px 0 37px;
  }
  #listItemCalculate {
    display: none;
  }
  .header .headerContainer .langSelector {
    margin: 0 0 0 16px;
  }
  #logoutButton {
    margin: 0 0 0 24px;
  }
  .topNavigation ul li a {
    font-size: 0.75rem;
  }
  #svgCalculate,
  #svgQuotes,
  #svgOrders,
  #svgAccounts {
    width: 26px;
    margin: 0 6px 0 0;
  }
}

@media only screen and (max-width: 640px) {
  .header {
    padding: 0 19px 0 18px;
  }
  #svgCalculate,
  #svgQuotes,
  #svgOrders,
  #svgAccounts {
    margin: 0 3px 0 0;
  }
  #svgLogout {
    width: 14px;
  }
  #svgUser {
    width: 26px;
  }
}
@media only screen and (max-width: 590px) {
  .header .hamburgerButton {
    display: flex;
  }

  .topNavigation,
  .header .headerContainer {
    display: none;
  }
}
