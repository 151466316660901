.arrow-next, .arrow-prev{
    width: 49px;
    height: 49px;
    background-color: var(--secondary-color);
    border: none;
    border-radius: 50%;
    display: flex;   
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 138px;
    transform: none;
}

@media (hover: hover) {
    .arrow-next:hover, 
    .arrow-prev:hover{
        background-color: var(--primary-color);
    }
}

.arrow-prev{
    transform: rotate(180deg);
    left: 18px;
    z-index: 100;
}

.arrow-next{
    right: 18px;
}

.arrow-next svg, .arrow-prev svg{
    width: 12px;
    transform: translate(2px, 0);
}

.arrow-next svg path, .arrow-prev svg path{
    fill: #fff;
}

@media only screen and (max-width: 1280px) {
    .arrow-next{
        right: 12px;
    }
    .arrow-prev{
        left: 12px;
    }
}

@media only screen and (max-width: 960px) {
    .arrow-next, .arrow-prev{
        width: 35px;
        height: 35px;
        top: 130px;
    }
    .arrow-next{
        right: 56px;
    }
    .arrow-prev{
        left: 56px;
    }
    .arrow-next svg, .arrow-prev svg{
        width: 9px;
    }
}

@media only screen and (max-width: 640px) {
    .arrow-next, .arrow-prev{
        top: 100px;
    }
    .arrow-next{
        right: 10px;
    }
    .arrow-prev{
        left: 10px;
    }
}

@media only screen and (max-width: 332px) {
    .arrow-next{
        top: 79px;
        right: 0;
        transform: translate( 50%, 0 );
    }
    .arrow-prev{
        top: 79px;
        left: 0;
        transform: translate( -50%, 0 ) rotate(180deg);
    }
}
