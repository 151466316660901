@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap");

.dashboard {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 23px 0 60px 0;
}

.dashboard-divider {
  width: 100vw;
  height: 158px;
  background: linear-gradient(
    315deg,
    var(--secondary-color),
    var(--primary-color)
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fbfbfb;
  font-size: 1.875rem;
  font-family: "Karla", sans-serif;
  font-weight: 700;
  margin: 63px 0 45px 0;
}
.dashboard-divider button {
  background-color: var(--secondary-color);
  color: #fbfbfb;
  font-size: 0.6875rem;
  border: none;
  border-radius: 4px;
  font-family: "Karla", sans-serif;
  font-weight: 700;
  margin: 19px 0 0 0;
  padding: 11px 45px 11px 45px;
  cursor: pointer;
  text-transform: uppercase;
}
.dashboard-divider button:hover {
  background-color: #2444a3;
}

@media only screen and (max-width: 960px) {
  .dashboard {
    padding: 30px 0 30px 0;
  }
  .dashboard-divider {
    font-size: 1.625rem;
    margin: 30px 0 30px 0;
  }
  .dashboard-divider button {
    font-size: 0.875rem;
    padding: 9px 37px 9px 37px;
  }
}

@media only screen and (max-width: 640px) {
  .dashboard {
    padding: 27px 0 30px 0;
  }
  .dashboard-divider {
    font-size: 1.5rem;
    margin: 26px 0 30px 0;
  }
}

@media only screen and (max-width: 320px) {
  .dashboard {
    padding: 27px 0 23px 0;
  }
  .dashboard-divider {
    font-size: 1.25rem;
    margin: 26px 0 22px 0;
  }
  .dashboard-divider button {
    font-size: 0.75rem;
    padding: 10px 43px 10px 43px;
  }
}
