@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap);

.topNavigation ul li a:hover .svgCalculate-1,
.topNavigation ul li a:hover .svgQuotes-1,
.topNavigation ul li a:hover .svgOrders-1,
.topNavigation ul li a:hover .svgAccounts-1{
  fill: #FDFDFD;
}

.topNavigation ul li a:hover .svgCalculate-1,
.topNavigation ul li a:hover .svgQuotes-1,
.topNavigation ul li a:hover .svgOrders-1,
.topNavigation ul li a:hover .svgAccounts-1{
  fill: #FDFDFD;
}

.topNavigation ul li a:hover .svgCalculate-2,
.topNavigation ul li a:hover .svgQuotes-2,
.topNavigation ul li a:hover .svgOrders-2,
.topNavigation ul li a:hover .svgOrders-3,
.topNavigation ul li a:hover .svgAccounts-2{
  fill: var(--secondary-color);
}
.topNavigation ul li a:hover .svgQuotes-3{
  stroke: var(--secondary-color);
}
#svgCalculate,
#svgQuotes,
#svgOrders,
#svgAccounts{
  width: 34px;
  margin: 0 15px 0 0;
}

.svgCalculate-1,
.svgQuotes-1,
.svgOrders-1,
.svgAccounts-1{
  fill: var(--primary-color);
}
.svgCalculate-2,
.svgQuotes-2,
.svgOrders-2,
.svgOrders-3,
.svgAccounts-2{
  fill: #8a8ea2;
}
.svgQuotes-3{
  fill: none;
  stroke: #8a8ea2;
  stroke-linecap: round;
  stroke-width: 1.5;
}

.popup{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 100%;

  background-color: rgba(27,27,27,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.popup-container{
  font-family: 'Karla', sans-serif;
  background-color: #FFFFFF;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 60px 20px 50px;
  min-width: 340px;
  max-width: 85vw;
  max-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-container form {
  width: 100%;
  display: contents;
}

.popup-container ::-webkit-scrollbar{
  -webkit-appearance: none;
}
.popup-container ::-webkit-scrollbar:vertical{
  width: 10px;
}
.popup-container ::-webkit-scrollbar:horizontal{
  height: 0;
}
.popup-container ::-webkit-scrollbar-thumb{
  border-radius: 3px;
  background-color: #E2E9F0;
}
.popup-container ::-webkit-scrollbar-track-piece{
  -webkit-appearance: none;
}

.popup-headerContainer{
  display: flex; 
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.popup-popupTitle{
  margin: 0 0 5px 0;
  padding: 0; 
  font-weight: 700;
  font-size: 1.5rem;
  align-self: flex-start;
  color: var(--primary-color);
  font-family: 'Poppins', sans-serif;
}
#closePopupButton{
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor:pointer;
  height: 20px;
  width: 22px;
  margin-left: 30px;
  margin-right: -40px;
}
#svgClosePopup{
  height: 100%;
  width: 100%;
}
.svgClosePopup-1{
  fill: var(--secondary-color);
}
.svgClosePopup-2{
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-width: 1;
}
.popup-popupButton{
  background-color: var(--secondary-color);
  border: none;
  border-radius: 4px; 
  color: #FBFBFB;
  cursor: pointer;
  padding: 11px 23px 11px 23px;
  font-size: 0.6875rem;
  text-transform: uppercase;
  font-family: 'Karla', sans-serif;
  font-weight: 700;
}
.popup-popupButton.red {
  background-color: #E12626;
}
.popup-popupButton.green {
  background-color: #0CAA1B;
}
.popup .popup-popupButton.disabled {
  background-color: #6A6A6A;
  pointer-events: none;
  cursor: default;
}

.popup .popup-linkContainer {
  margin: 16px 0;
}

.popup .popup-linkContainer a{ 
  color: var(--secondary-color);
  margin: 0 59px 0 0;
}

.popup-section {
  border-top: 1px solid #E2E9F0;
  padding: 18px 0;
  display: flex;
  width: 100%;
  padding-left: 48px;
  padding-right: 48px;
}

.popup-buttonSection {
  padding-top: 34px;
  justify-content: space-evenly;
}

.popup-section:first-child {
  padding-top: 34px;
}

.popup-column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.popup-column:not(:first-child) {
  margin-left: 100px;
}

.popup-inputContainer {
  margin: 0 0 19px 0;
}

.popup-section h2 {
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--primary-color);
  font-family: 'Poppins', sans-serif;
}

.popup-inputContainer input,
.popup-inputContainer select {
  font-size: 0.875rem;
  font-family: 'Karla', sans-serif;
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid #E2E9F0;
  border-radius: 4px; 
  width: 204px;
  height: 42px;
  padding: 0 11px 0 11px;
}

.popup-inputContainer [type="color"] {
  cursor: pointer;
}

.popup-inputContainer [type="file"] {
  padding: 11px;
  cursor: pointer;
}

.popup-inputContainer ::file-selector-button {
  display :none;
}

@media only screen and (max-width: 420px) {
  .popup .popup-popupTitle{
    width: 250px;
  }
  .popup .popup-headerContainer{
    width: 250px;
  }
  .popup form{
    width: 250px;
  }
  .popup .popup-inputContainer input,
  .popup .popup-inputContainer select{
    width: 250px;
  }
  .popup .popup-linkContainer a{
    margin: 0;
  }
  #closePopupButton{
    margin-right: -20px;
  }
}

@media only screen and (max-width: 370px) {
  .popup .popup-popupTitle{
    width: 100px;
  }
  .popup .popup-headerContainer{
    width: 200px;
  }
  .popup form{
    width: 200px;
  }
  .popup .popup-inputContainer input,
  .popup .popup-inputContainer select{
    width: 200px;
  }
  .popup .popup-linkContainer a{
    margin: 0;
  }
  
}

@media only screen and (max-width: 840px) {
  .popup .popup-column:not(:first-child){
    margin-left: 50px;
  }
  .popup .popup-section {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 790px) {
  .popup .popup-column:not(:first-child){
    margin-left: 30px;
  }
}

@media only screen and (max-width: 770px) {
  .popup .popup-section {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 690px) {
  .popup .popup-column:not(:first-child){
    margin-left: 0;
  }
  .popup .popup-column{
    width: 100%;
  }
  .popup .popup-section{
    flex-wrap: wrap;
  }
}

.userHover {
  width: 100%;
  background-color: #fff;
  display: none;
  position: absolute;
  top: 75px;
  z-index: 1;
  border-radius: 0 0 10px 10px;
  box-shadow: 3px 3px 25px rgb(0 0 0 / 7%);
  flex-direction: column;
  overflow: hidden;
}

.userHover .action:link,
.userHover .action:visited {
  color: #333;
  padding: 16px 24px;
  width: 100%;
  text-align: center;
  margin: 0;
  text-decoration: none;
}

.userHover .action:link:hover,
.userHover .action:visited:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

#hamburgerMenu {
  height: 100vh;
  width: 100vw;
  background-color: #fbfbfb;
  position: fixed;
  top: 0;
  z-index: 200;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
#hamburgerMenu .hamburgerHeaderWrapper {
  height: 75px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 19px;
  align-items: center;
}
#hamburgerMenu .hamburgerMenu-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  color: var(--primary-color);
  padding: 27px 20px 15px 20px;
}
#hamburgerMenu .first-title {
  padding-left: 0;
}

#hamburgerMenu .hamburgerMenu-link,
#hamburgerMenu select {
  border: none;
  font-size: 1rem;
  font-family: "Karla", sans-serif;
  font-weight: 700;
  color: var(--primary-color);
  border-top: 1px solid #8a8ea2;
  text-decoration: none;
  padding: 15px 38px 15px 38px;
  cursor: pointer;
}
#hamburgerMenu .hamburgerMenu-lastLink,
#hamburgerMenu select {
  border-bottom: 1px solid #8a8ea2;
}
#hamburgerMenu .hamburgerButton {
  border: none;
  background: none;
  display: flex;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  width: 30px;
  height: 21px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
#hamburgerMenu .hamburgerButton .hamburgerLine {
  width: 100%;
  height: 3px;
  border-radius: 1.5px;
  background-color: var(--primary-color);
}

.contactPopup .contactPopup-inputZipCode {
  width: 80px;
  margin-right: 16px;
}
.contactPopup .contactPopup-inputEmail{
  max-width: 300px;
  width: 300px;
  min-width: 100%;
}
.contactPopup .popup-checkboxInput:not(:last-child){
  margin-right: 20px;
}

@media only screen and (max-width: 690px) {
  .contactPopup .popup-claimsContainer,
  .contactPopup .popup-documentsContainer{
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 420px) {
  .contactPopup .contactPopup-inputZipCode {
    width: 80px !important;
  }
}

@media only screen and (max-width: 370px) {
  .contactPopup .contactPopup-inputZipCode {
    width: 80px !important;
  }
}





.header {
  width: 100%;
  flex-basis: 75px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: var(--primary-color);
  color: #8a8ea2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 40px 0 37px;
  z-index: 2;
}
#headerLogoWicam {
  max-height: 69px;
  width: auto;
  -webkit-transform: translate(0, 5px);
          transform: translate(0, 5px);
}
.topNavigation {
  height: 75px;
  flex-grow: 1;
  overflow: hidden;
  padding: 0 0 0 20px;
}
.topNavigation ul {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
}
.topNavigation ul li {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  height: 100%;
  flex-grow: 1;
  padding: 0 20px;
  min-width: 0;
}
.topNavigation ul li a {
  margin: 0 -20px;
  padding: 0 20px;
  color: #8a8ea2;
  text-decoration: none;
  font-family: "Karla", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.topNavigation ul li a > span {
  display: contents;
}

.topNavigation ul li a:hover {
  background-color: var(--secondary-color);
  color: #fdfdfd;
}

.topNavigation ul li a svg {
  flex-shrink: 0;
}

.topNavigation ul li a span {
  overflow: hidden;
  text-overflow: ellipsis;
}

#svgUser {
  width: 30px;
  margin: 0 11px 0 0;
}
.svgUser-1 {
  fill: var(--secondary-color);
}
.svgUser-2 {
  fill: #fdfdfd;
}

#svgLogout {
  width: 24px;
}
.svgLogout-1 {
  fill: #8a8ea2;
}
#logoutButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.header .headerContainer {
  display: flex;
  align-items: center;
  font-family: "Karla", sans-serif;
  font-size: 1.25rem;
  color: #fdfdfd;
  height: 100%;
}

.header .headerContainer .langSelector {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FDFDFD%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  padding: 0 17px 0 0;
  background-repeat: no-repeat, repeat;
  background-position: right 0 top 50%, 0 0;
  background-size: 11px auto, 100%;
  border: none;
  color: #fdfdfd;
  margin: 0 32px 0 16px;
}
.header .headerContainer .langSelector option {
  padding: 20px;
  color: #000000;
}

.header .headerContainer .userContainer {
  display: flex;
  align-items: center;
  padding: 0 16px;
}
.header .headerContainer .userContainerDropdownWrapper:hover #userHover {
  display: flex;
}
.header .headerContainer .userContainerDropdownWrapper:hover .userContainer {
  background-color: var(--secondary-color);
  color: #fdfdfd;
}
.userContainerDropdownWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  height: 100%;
}

.header .headerContainer .userContainer span {
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header .hamburgerButton {
  display: none;
  border: none;
  background: none;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  width: 30px;
  height: 21px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.header .hamburgerButton .hamburgerLine {
  width: 100%;
  height: 3px;
  border-radius: 1.5px;
  background-color: #fbfbfb;
}

@media only screen and (max-width: 1470px) {
  .header .headerContainer .userContainer span {
    max-width: 120px;
  }
}

@media only screen and (max-width: 1370px) {
  .header .headerContainer .userContainer span {
    max-width: 80px;
  }
}

@media only screen and (max-width: 1280px) {
  .header {
    padding: 0 25px 0 37px;
  }
  .header .headerContainer .userContainer span {
    display: none;
  }
  #userHover {
    width: auto;
  }
  #svgUser {
    margin: 0 0 0 0;
  }
}

@media only screen and (max-width: 1085px) {
  .header {
    padding: 0 33px 0 37px;
  }
  #listItemCalculate {
    display: none;
  }
  .header .headerContainer .langSelector {
    margin: 0 0 0 16px;
  }
  #logoutButton {
    margin: 0 0 0 24px;
  }
  .topNavigation ul li a {
    font-size: 0.75rem;
  }
  #svgCalculate,
  #svgQuotes,
  #svgOrders,
  #svgAccounts {
    width: 26px;
    margin: 0 6px 0 0;
  }
}

@media only screen and (max-width: 640px) {
  .header {
    padding: 0 19px 0 18px;
  }
  #svgCalculate,
  #svgQuotes,
  #svgOrders,
  #svgAccounts {
    margin: 0 3px 0 0;
  }
  #svgLogout {
    width: 14px;
  }
  #svgUser {
    width: 26px;
  }
}
@media only screen and (max-width: 590px) {
  .header .hamburgerButton {
    display: flex;
  }

  .topNavigation,
  .header .headerContainer {
    display: none;
  }
}

.footer{
  width: 100%;
  flex-basis: 75px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: var(--primary-color);
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 316px 0 267px;
  z-index: 2;
}
.footer .copyrightSpan{
  font-family: 'Karla', sans-serif;
  font-size: 0.875rem;
}
.footer-footerNavigation ul{
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  display: flex;
  flex-direction: row;
}
.footer-footerNavigation ul li{
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  margin: 0 0 0 48px;
}

.footer-footerNavigation ul li a{
  color: #FFFFFF;
  text-decoration: none;
  font-family: 'Karla', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
}


@media only screen and (max-width: 1280px) {

  .footer{
    justify-content: flex-end;
    padding: 0 34px 0 0;
  }
  .footer .copyrightSpan{
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}

@media only screen and (max-width: 960px) {
  .footer .copyrightSpan,
  .footer-footerNavigation ul li a{
    font-size: 0.75rem;
  }
  .footer-footerNavigation ul li{
    margin: 0 0 0 20px;
  }
}

@media only screen and (max-width: 710px) {
  .footer{
    justify-content: space-between;
    padding: 0 22px 0 25px;
  }
  .footer .copyrightSpan{
    position: inherit;
    left: 0;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }

  .footer-footerNavigation ul li a{
    font-size: 0.625rem;
  }
  .footer-footerNavigation ul li{
    margin: 0 0 0 15px;
  }

}

@media only screen and (max-width: 400px) {
  .footer{
    flex-direction: column-reverse;
    padding: 16px 0 15px 0;
  }
  .footer-footerNavigation ul li a{
    font-size: 0.75rem;
  }
  .footer-footerNavigation ul li{
    margin: 0 0 0 0;
  }
  .footer-footerNavigation ul{
    width: 259px;
    justify-content: space-between;
  }
}

.authenticationPage {
  background-image: url(/static/media/authentication-background.4d34bd0f.png);
  background-size: cover;
  background-position: center;
}

.authenticationPage .popup-popupButton {
  min-width: 135px;
}

.authenticationPage .popup-popupTitle {
  margin: 18px 0 12px 0;
}

.authenticationPage .popup-container {
  padding-left: 175px;
  padding-right: 175px;
}

.authenticationPage .popup-inputContainer input {
  width: 300px;
}

.authenticationPage .popup-linkContainer a { 
  color: #2444A3;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

@media only screen and (max-width: 760px) {
  .authenticationPage .popup-popupTitle{
    width: 300px;
    margin: 18px auto 12px auto;
  }
  .authenticationPage .popup-container {
    padding-left: 0;
    padding-right: 0;
    width: 85vw;

  }

}

@media only screen and (max-width: 420px) {
  .authenticationPage .popup-popupTitle{
    width: 250px;
  }
  .authenticationPage form{
    width: 250px;
  }
  .authenticationPage .popup-inputContainer input{
    width: 250px;
  }
  .authenticationPage .popup-linkContainer a{
    margin: 0;
  }
}

@media only screen and (max-width: 370px) {
  .authenticationPage .popup-popupTitle{
    width: 200px;
  }
  .authenticationPage form{
    width: 200px;
  }
  .authenticationPage .popup-inputContainer input{
    width: 200px;
  }
  .authenticationPage .popup-linkContainer a{
    margin: 0;
  }
}
/**
 * We know this stylesheet will always be included.
 * And since we want to avoid inline styles to prevent injection,
 * we'll just put the global styles here.
 **/

html, body {
  margin: 0;
  border: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-pageContainer {
  flex-grow: 1;
  display: flex;
  position: relative;
}

.pageContainer {
  flex-grow: 1;
  display: flex;
  width: 100%;
}

.pageContainer > div {
  flex-grow: 1;
  width: 100%;
}

.loadingContainer {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingContainer .loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid var(--secondary-color);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: loadingSpin 1s linear infinite;
          animation: loadingSpin 1s linear infinite;
}

img {
  position: relative;
  text-indent: -100%;
}

img:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.05);
}

img:after {
  content: '';
  display: block;
  position: absolute;
  top: calc(50% - 18px);
  left: calc(50% - 18px);

  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--secondary-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-animation: loadingSpin 1s linear infinite;
          animation: loadingSpin 1s linear infinite;
}

@-webkit-keyframes loadingSpin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes loadingSpin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.news{
    color: var(--primary-color);
    font-family: 'Karla', sans-serif;
    width: 1386px;
    position: relative;
}
.news-title{
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
}
.news-horizontalLine{
    width: 100%;
    height: 1px;
    background-color: #E2E9F0 ;
    margin: 11px 0 21px 0;
}
.news-newsItemsContainer{
    width: 100%;
}
.news .slick-list {
    margin: 0 -15px;
}

.news .slick-slide {
    padding: 0 15px;
}

@media only screen and (max-width: 1476px) {
    .news{
      width: calc(100vw - 90px);
    }
}
@media only screen and (max-width: 960px) {
    .news-newsItemsContainer{
        margin: 0 38px 0 38px;
        width: calc( 100% - 38px - 38px );
    }
}


@media only screen and (max-width: 640px) {
    .news{
      width: calc(100vw - 44px);
    }
    .news-newsItemsContainer{
        margin: 0 0 0 0;
        width: 100%;
    }
}
.arrow-next, .arrow-prev{
    width: 49px;
    height: 49px;
    background-color: var(--secondary-color);
    border: none;
    border-radius: 50%;
    display: flex;   
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 138px;
    -webkit-transform: none;
            transform: none;
}

@media (hover: hover) {
    .arrow-next:hover, 
    .arrow-prev:hover{
        background-color: var(--primary-color);
    }
}

.arrow-prev{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    left: 18px;
    z-index: 100;
}

.arrow-next{
    right: 18px;
}

.arrow-next svg, .arrow-prev svg{
    width: 12px;
    -webkit-transform: translate(2px, 0);
            transform: translate(2px, 0);
}

.arrow-next svg path, .arrow-prev svg path{
    fill: #fff;
}

@media only screen and (max-width: 1280px) {
    .arrow-next{
        right: 12px;
    }
    .arrow-prev{
        left: 12px;
    }
}

@media only screen and (max-width: 960px) {
    .arrow-next, .arrow-prev{
        width: 35px;
        height: 35px;
        top: 130px;
    }
    .arrow-next{
        right: 56px;
    }
    .arrow-prev{
        left: 56px;
    }
    .arrow-next svg, .arrow-prev svg{
        width: 9px;
    }
}

@media only screen and (max-width: 640px) {
    .arrow-next, .arrow-prev{
        top: 100px;
    }
    .arrow-next{
        right: 10px;
    }
    .arrow-prev{
        left: 10px;
    }
}

@media only screen and (max-width: 332px) {
    .arrow-next{
        top: 79px;
        right: 0;
        -webkit-transform: translate( 50%, 0 );
                transform: translate( 50%, 0 );
    }
    .arrow-prev{
        top: 79px;
        left: 0;
        -webkit-transform: translate( -50%, 0 ) rotate(180deg);
                transform: translate( -50%, 0 ) rotate(180deg);
    }
}

.news-newsItem {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin: 0;
  border-radius: 10px;
  border: 1px solid #e2e9f0;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03);
  min-height: 600px;
}

.news-newsItem .fileUpload {
  border: none;
  border-radius: 10px 10px 0 0;
  height: 280px;
  width: 100%;
  background-size: cover;
  background-position: center;
}
.news-newsItem .standardFileUpload {
  display: none;
}
.news-newsItem .overlay {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-newsItem .uploadText {
  text-align: center;
}
.news-newsItemDivider {
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  background-color: var(--primary-color);
  font-family: "Karla", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  display: flex;
  padding: 0 12px 0 12px;
  justify-content: space-between;
  align-items: center;
}
.news-newsItemDivider .news-newsItemAuthor {
  color: #ffffff;
  background-color: transparent;
  border: none;
}
.news-newsItemDivider .news-newsItemDate {
  color: #ffffff;
  opacity: 0.5;
  background-color: transparent;
  border: none;
  text-align: end;
}

.news-newsItem .standardButton {
  position: absolute;
  left: calc(100% - 40px);
  background: none;
  border: none;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-newsItem .standardButton svg {
  width: 50%;
}
.news-newsItem .editButton {
  top: 10px;
  background-color: var(--primary-color);
}
.news-newsItem .saveButton {
  top: 50px;
  background-color: var(--secondary-color);
}
.news-newsItem .cancelButton {
  top: 10px;
  background-color: var(--secondary-color);
}
.news-newsItem .deleteButton {
  background-color: red;
  top: 90px;
}

.news-newsItem .news-newsItemTitle,
.news-newsItem .news-newsItemTitle:disabled {
  font-family: "Karla", sans-serif;
  resize: none;
  color: var(--primary-color);
  margin: 18px 20px 15px 16px;
  font-size: 1.875rem;
  font-weight: 700;
  background-color: transparent;
  border: none;
  overflow-y: hidden;
}
.news-newsItem .news-newsItemExcerpt,
.news-newsItem .news-newsItemExcerpt:disabled {
  font-family: "Karla", sans-serif;
  resize: none;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0 20px 26px 16px;
  background-color: transparent;
  border: none;
  overflow-y: hidden;
}

@media only screen and (max-width: 960px) {
  .news-newsItem img {
    height: 242px;
  }
  .news-newsItemDivider {
    height: 42px;
    padding: 0 10px 0 10px;
  }
  .news-newsItem .news-newsItemTitle {
    font-size: 1.25rem;
    margin: 16px 17px 15px 14px;
  }
  .news-newsItem .news-newsItemExcerpt {
    margin: 0 17px 16px 14px;
  }
}

@media only screen and (max-width: 640px) {
  .news-newsItem img {
    height: 182px;
  }
  .news-newsItemDivider {
    height: 31px;
    padding: 0 8px 0 8px;
  }
  .news-newsItem .news-newsItemTitle {
    font-size: 1.125rem;
    margin: 8px 13px 7px 10px;
  }
  .news-newsItem .news-newsItemExcerpt {
    margin: 0 13px 14px 10px;
  }
}

@media only screen and (max-width: 332px) {
  .news-newsItem {
    width: 100%;
    min-width: 100%;
  }
  .news-newsItem img {
    height: 174px;
  }
  .news-newsItemDivider {
    height: 30px;
    padding: 0 7px 0 7px;
    font-size: 0.75rem;
  }
  .news-newsItem .news-newsItemTitle {
    font-size: 1.125rem;
    margin: 8px 12px 9px 10px;
  }
  .news-newsItem .news-newsItemExcerpt {
    margin: 0 12px 20px 10px;
    font-size: 0.75rem;
  }
}

.addNewNewsItem{
  position: relative;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    margin: 0;
    border-radius: 10px;
    border: 1px solid #E2E9F0;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03);
    min-height: 600px;
}

.addNewItem{
  border: none;
  display: flex;
  background-color: var(--primary-color);
  color: white;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  border-radius: 10px;
  transition: background-color 500ms ease;
  flex-direction: column;
  font-family: 'Karla', sans-serif;
  cursor: pointer;
}
.addButton{
  width: 50px;
}

.addNewItem:hover{
  background-color: var(--secondary-color);
}

@media only screen and (max-width: 332px) {
  .addNewNewsItem{
      width: 100%;
      min-width: 100%;
  }
}
.statistic {
  box-sizing: border-box;
  min-width: 200px;
  width: 200px;
  height: 226px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #e2e9f0, 3px 3px 3px rgba(0, 0, 0, 0.03);
  padding: 20px 20px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.statistic:hover {
  box-shadow: 0 0 0 2px var(--secondary-color), 3px 3px 3px rgba(0, 0, 0, 0.03);
}

.statistic-icon {
  width: 25px;
  align-self: flex-start;
}
.statistic-statistic {
  color: var(--secondary-color);
  font-family: "Poppins", sans-serif;
  font-size: 1.875rem;
  font-weight: 700;
}
.statistic-subtitle {
  font-size: 0.625rem;
  color: var(--primary-color);
  margin: 5px 0 0 0;
  flex: 1 1;
}
.statistic-subtitleAmount {
  font-weight: 700;
}
.statistic-worth-container {
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 10px 0;
}
.statistic-worth {
  font-size: 0.625rem;
}
.statistic-worth-amount {
  font-size: 0.875rem;
  font-weight: 700;
}
.statistic-horizontalLine {
  width: 100%;
  height: 1px;
  background-color: #e2e9f0;
  margin: 0 0 10px 0;
}
.statistic-unit,
.statistic-difference-container {
  color: var(--primary-color);
  font-family: "Karla", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: capitalize;
}
.statistic-difference {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin: 0 10px 0 10px;
}

.statistic-difference-container svg {
  width: 0.5rem;
  margin: 0.1875rem 0 0.1875rem 0;
}

@media only screen and (max-width: 450px) {
  .statistic {
    min-width: 130px;
    width: 130px;
    height: auto;
  }
}

.chart{
  box-sizing: border-box;
  min-width: 390px;
  width: 390px;
  height: 226px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #e2e9f0, 3px 3px 3px rgba(0, 0, 0, 0.03);
  padding: 12px 30px 30px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart:hover{
  box-shadow: 0 0 0 2px var(--secondary-color), 3px 3px 3px rgba(0, 0, 0, 0.03);
}

.chartTitle{
  font-size: 0.75rem;
  font-family: 'Poppins', sans-serif;
  align-self: flex-start;
  margin: 0 0 5px 0;
}

.statistics{
    color: var(--primary-color);
    font-family: 'Karla', sans-serif;
    width: 1386px; 
}
.statistics-title{
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
}
.statistics-horizontalLine{
    width: 100%;
    height: 1px;
    background-color: #E2E9F0 ;
    margin: 11px 0 24px 0;
}

.statistics-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media only screen and (max-width: 1476px) {
    .statistics{
      width: calc(100vw - 90px);
    }
}
@media only screen and (max-width: 1300px) {
    .statistics-container{
        margin: 0 -5px;
        justify-content: center;
    }
    .statistic{
        margin: 0 5px;
    }
    .chart{
        margin: 24px 0 0 0;
    }

}

@media only screen and (max-width: 925px) {
    .statistic{
        margin: 0 5px 24px 5px;
    }
    .chart{
        margin: 0 5px;
        width: 410px;
    }
}

@media only screen and (max-width: 640px) {
    .statistics{
      width: calc(100vw - 44px);
    }
}

@media only screen and (max-width: 450px) {
    .chart{
      min-width: 270px;
      width: 270px;
      height: auto;
      padding: 12px 20px 34px 20px;
    }
  }
.dashboard {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 23px 0 60px 0;
}

.dashboard-divider {
  width: 100vw;
  height: 158px;
  background: linear-gradient(
    315deg,
    var(--secondary-color),
    var(--primary-color)
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fbfbfb;
  font-size: 1.875rem;
  font-family: "Karla", sans-serif;
  font-weight: 700;
  margin: 63px 0 45px 0;
}
.dashboard-divider button {
  background-color: var(--secondary-color);
  color: #fbfbfb;
  font-size: 0.6875rem;
  border: none;
  border-radius: 4px;
  font-family: "Karla", sans-serif;
  font-weight: 700;
  margin: 19px 0 0 0;
  padding: 11px 45px 11px 45px;
  cursor: pointer;
  text-transform: uppercase;
}
.dashboard-divider button:hover {
  background-color: #2444a3;
}

@media only screen and (max-width: 960px) {
  .dashboard {
    padding: 30px 0 30px 0;
  }
  .dashboard-divider {
    font-size: 1.625rem;
    margin: 30px 0 30px 0;
  }
  .dashboard-divider button {
    font-size: 0.875rem;
    padding: 9px 37px 9px 37px;
  }
}

@media only screen and (max-width: 640px) {
  .dashboard {
    padding: 27px 0 30px 0;
  }
  .dashboard-divider {
    font-size: 1.5rem;
    margin: 26px 0 30px 0;
  }
}

@media only screen and (max-width: 320px) {
  .dashboard {
    padding: 27px 0 23px 0;
  }
  .dashboard-divider {
    font-size: 1.25rem;
    margin: 26px 0 22px 0;
  }
  .dashboard-divider button {
    font-size: 0.75rem;
    padding: 10px 43px 10px 43px;
  }
}

.notification{
  max-width: 600px;
  width: 100%;
  padding: 20px;
  position: relative;
  font-family: 'Karla', sans-serif;
  font-size: 1rem;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 0 10px 0;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03);
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.notification.error{
  background-color:#E12626;
  color: #FDFDFD;
}

.notification.success{
  background-color: #0CAA1B;
  color: #FDFDFD;
}

.notification.warning{
  background-color: #E1AA1B;
  color: #FDFDFD;
}

.notification.info{
  background-color: var(--secondary-color);
  color: #FDFDFD;
}

.notification .progress{
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  background-color: rgba(255,255,255,0.6);
  transition: right 1s linear;
}
.notificationContainer{
  position: fixed;
  left: 10px;
  right: 10px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
}
.calculation{
  font-family: 'Karla', sans-serif;
  width: 100vw;
  padding: 30px calc( (100vw - 1386px) / 2 ) 37px calc( (100vw - 1386px) / 2 );
  min-height: 100vh;
  background-color: #FBFBFB;
  display: flex;
  flex-direction: row;
}

.partsListContainer{
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.partPreviewContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.partPreviewContainer-title, .partsListContainer-title{
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
  font-family: 'Poppins', sans-serif;
  margin-bottom: 8px;
}

@media only screen and (max-width: 1476px) {
  .calculation {
    padding: 30px 45px 37px 45px;
  }
}

.modelPreview {
  position: relative;

  height: calc(100% - 37px);
  border: 1px solid #E2E9F0;
  border-radius: 10px;
  width: 100%;
  font-size: 0.875rem;
  font-family: 'Karla', sans-serif;
  color: #181F47;
  padding: 23px 14px 23px 14px;

  display: flex;
  flex-direction: column;
}

.modelPreview-title {
  text-transform: uppercase;
}

.modelPreview-title span:first-child {
  font-weight: bold;
}

.modelPreview .selectedPartType {
  font-weight: 700;
}

.modelPreview-svg {
  flex-grow: 1;
  position: relative;
}

.modelPreview .react-transform-wrapper {
  margin-top: 5%;
  margin-left: 5%;
  width: 90%;
  height: 90%;
}

.modelPreview .react-transform-component {
  width: 100%;
  height: 100%;
}

.modelPreview-canvas {
  flex-grow: 1;
}

.modelPreview .switchContainer {
  position: absolute;
  top: 23px;
  right: 14px;
  display: flex;
}

.modelPreview .generalButton:disabled {
  background-color: var(--secondary-color-light);
  cursor: inherit;
}

.checkboxContainer{
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 22px;
}
.checkboxContainer input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark{
  position: absolute;
  top: calc(50% - 7px);
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 2px var(--primary-color) solid;
  border-radius: 2px;
}

.checkboxContainer input:checked ~ .checkmark {
  background-color: var(--primary-color);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

.checkboxContainer .checkmark:after {
  left: calc(50% - 2.5px);
  top: 0;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.listItem{
  padding: 0 20px 0 15px;
}

.listItem .listItem-header{
  padding: 12px 0;
  border-bottom: 1px solid #E2E9F0;
  font-family: 'Karla', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  color: #181F47;
  display: flex;
  align-items: center;
}

.listItem .listItem-header .listItem-header-itemName{
  margin-left: 10px;
  font-family: 'Poppins', sans-serif;
}


.listItem .listItem-main{
  padding: 15px 30px 15px 0; 
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.listItem .listItem-main.valid {
  background-color: rgba(38, 225, 38, 0.25);
}

.listItem .listItem-main.invalid {
  background-color: rgba(225, 38, 38, 0.25);
}

.listItem .listItem-main .listItem-image {
  width: auto;
  margin-right: 10px;
  height: 90px;
  cursor: zoom-in;
}

.listItem .listItem-main .listItem-main-informationContainer{
  line-height: 1.3;
  display: flex;
}

.listItem .listItem-main .listItem-main-informationContainer .columnLeft{
  width: 45%;
  min-width: 110px;
  font-size: 0.6875rem;
  color: #181F47;
}

.listItem .listItem-main .listItem-main-informationContainer .columnLeft .property,
.listItem .listItem-main .listItem-main-informationContainer .columnRight .property{
  font-family: 'Karla', sans-serif;
  font-weight: 700;
    
  
}

.listItem .listItem-main .listItem-main-informationContainer .columnRight{
  width: 55%;
  font-size: 0.6875rem;
  color: #181F47;
  display: flex;
  min-width: 135px;
}

.listItem .listItem-main .listItem-main-informationContainer .columnRight .operationsContainer{
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.listItem .listItem-main .listItem-main-informationContainer .columnRight .operationsContainer span{
  display: block;
}
.listSubItemContainer{
  display: flex;
  flex-direction: row;
}
.listSubItem{
  padding: 0 5px 0 5px;
}
.listSubItem-warning{
  width: 20px;
  padding-top: 6px;
 
}

.listSubItem-warning svg{
  -webkit-transform: translate(-5px, 0);
          transform: translate(-5px, 0);
}

.listSubItem-warningBox{
  border: 1px #E2E9F0 solid;
  border-radius: 10px;
  margin-bottom: 5px;
}

.listSubItem .listSubItem-header{
  padding: 4px 0;
  font-family: 'Karla', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  color: #181F47;
  display: flex;
  align-items: center;
}

.listSubItem .listSubItem-header .listItem-header-itemName{
  margin-left: 10px;
  font-family: 'Karla', sans-serif;
}


.listSubItem .listSubItem-main{
  padding: 15px 30px 15px 0; 
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.listSubItem .listSubItem-main .listSubItem-image{
  width: auto;
  margin-right: 10px;
  height: 90px;
  cursor: zoom-in;
}

.listSubItem .listSubItem-main .listSubItem-main-informationContainer{
  line-height: 1.3;
  display: flex;
}

.listSubItem .listSubItem-main .listSubItem-main-informationContainer .columnLeft{
  width: 45%;
  min-width: 110px;
  font-size: 0.6875rem;
  color: #181F47;
}

.listSubItem .listSubItem-main .listSubItem-main-informationContainer .columnLeft .property,
.listSubItem .listSubItem-main .listSubItem-main-informationContainer .columnRight .property{
  font-family: 'Karla', sans-serif;
  font-weight: 700;
  
}

.listSubItem .listSubItem-main .listSubItem-main-informationContainer .columnRight{
  width: 55%;
  font-size: 0.6875rem;
  color: #181F47;
  display: flex;
  min-width: 135px;
}

.listSubItem .listSubItem-main .listSubItem-main-informationContainer .columnRight .operationsContainer{
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.listSubItem .listSubItem-main .listSubItem-main-informationContainer .columnRight .operationsContainer span{
  display: block;
}
.partsList{
  width: 796px;
  border-radius: 10px;
  position:relative;
  overflow: hidden;
  height: calc(100% - 37px);
}

.partsList .partsList-header{
  background-color: var(--primary-color);
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 16px;
}
#upload-svg{
  width: 18px;
  
}
.partsList .partFileUpload {
  display: none;
}

.partsList .partFileUpload + .generalButton {
  margin-right: 0;
}

.partsList .partFileLabel {
  margin-top: -10px;
  padding: 15px 10px 5px 10px;
  border-radius: 0 0 5px 5px;
  background-color: var(--secondary-color-light);
}

.partsList .partFileLabel:empty {
  padding: 0;
}

.generalButton{
  border: none;
  background: none;
  background-color: var(--secondary-color);
  padding: 9px 12px 9px 12px;
  display: flex;
  align-items: center;

  color: #FBFBFB;
  font-family: 'Karla', sans-serif;
  font-weight: 700;
  font-size: 0.6875rem;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  margin-right: 16px;
}
.generalButton svg{
  margin-right: 11px;
}
.partsList-container{
  border-right: 1px solid #E2E9F0;
  border-left: 1px solid #E2E9F0;
}
.partsList-container-noUpload{
  height: calc(100% - 70px);
  border: 1px solid #E2E9F0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: var(--primary-color);
  padding: 40px 0 0 30px;
}
.partsList-noUpload{
  margin: 0;
  padding: 0;
}
.partsList-controlsContainer{
  padding: 0 37px 0 31px;
  height: 50px;
  width: 58%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.partsList-checkboxContainer{
  display: flex;
  align-items: center;
}

.partsList-checkboxContainer svg{
  margin-left: 5px;
  cursor: pointer;
}
#delete-btn{
  border: none;
  background: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#delete-btn:hover {
  background-color: #E2E9F0;
}
#delete-btn:hover .delete-btn-svg-1{
  fill: var(--secondary-color);
}
#delete-btn-svg{
  height: 18px;
}
.delete-btn-svg-1{
  fill: #2444a3;
}
.partsList-mainContainer{
  
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 5px 37px 0 16px;
  max-height: 80vh;
  min-height: 650px;
}

.partsList-list{
  width: 60%;
  margin-right: 20px;
  overflow: scroll;
  margin-bottom: 20px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.partsList-list::-webkit-scrollbar{
  -webkit-appearance: none;
}
.partsList-list::-webkit-scrollbar:vertical{
  width: 10px;
}
.partsList-list::-webkit-scrollbar:horizontal{
  height: 0;
}
.partsList-list::-webkit-scrollbar-thumb{
  border-radius: 3px;
  background-color: #E2E9F0;
}
.partsList-list::-webkit-scrollbar-track-piece{
  -webkit-appearance: none;
}

.partsList .partsList-manufactureSettingsContainer{
  width: 40%;
}
.partsList .partsList-manufactureSettingsContainer .title{
  font-family: 'Poppins', sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--primary-color);
  border-bottom: 1px solid #E2E9F0;
  width: 100%;
  padding: 12px 0 6px 0;
  margin: 0 0 12px 0;
}
.partsList-manufactureSettingsContainer .manufactureContainer{
  display: flex;
  flex-direction: column;
}
.partsList-manufactureSettingsContainer .manufactureContainer span{
  font-family: 'Karla', sans-serif;
  color: var(--primary-color);
  font-weight: 700;
  font-size: 0.875rem;
}

.partsList-manufactureSettingsContainer .manufactureContainer input,
.partsList-manufactureSettingsContainer .manufactureContainer select{
  width: 164px;
  height: 42px;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  background-color: transparent;
  padding: 0 17px;
  margin: 4px 0 12px 0;
  font-family: 'Karla', sans-serif;
}

.partsList-manufactureSettingsContainer .operationsContainer{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}


.partsList-manufactureSettingsContainer .operationsContainer input,
.partsList-manufactureSettingsContainer .operationsContainer select{
  width: 250px;
  height: 42px;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  background-color: transparent;
  padding: 0 17px;
  margin: 4px 0 8px 0;
  font-family: 'Karla', sans-serif;
}

.partsList-manufactureSettingsContainer .operationsContainer .checkboxContainer.disabled {
  opacity: 0.5;
}

.partsList-manufactureSettingsContainer .operationsContainer .checkboxContainer {
  filter: alpha(opacity=50);
  font-size: 0.875rem;
  font-weight: 700;
  width: 50%;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.partsList-manufactureSettingsContainer .buttonContainer{
  display: flex;
  flex-direction: column;
}


.partsList .partsList-footer{
  background-color: var(--primary-color);
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 37px;
}


.uploadPopup {
  width: 920px;
  max-width: 100%;
  display: flex;
  overflow-y: hidden;
}

.uploadPopup .popup-column {
  align-items: stretch;
}

.uploadPopup .progressContainer {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropzone {
  display: block;
  text-align: center;
  position: relative;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23E2E9F0' stroke-width='1' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 20px;
  padding: 30px;
  margin-top: 20px;
}

.dropzone::after {
  content: attr(data-label);
  font-size: 24px;
  font-weight: bold;
  font-family: 'Poppins';
  text-transform: uppercase;
  color: var(--primary-color);
}

.dropzone input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.dropzone input:drop {
  opacity: 1;
}

.uploadProgress {
  width: 100%;
  max-width: 420px;
  margin-bottom: 40px;
  display: flex;
}

.uploadProgress .icon {
  flex-grow: 0;
  flex-shrink: 0;
}

.uploadProgress .icon svg {
  display: block;
  height: 62px;
}

.uploadProgress .info {
  margin-left: 26px;
  flex-grow: 1;
  min-width: 0;

  display: flex;
  flex-direction: column;
}

.uploadProgress .name {
  flex-grow: 1;
  min-width: 0;

  color: var(--primary-color);
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: bold;

  display: flex;
  align-items: center;
}

.uploadProgress .name span {
  flex-grow: 1;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.uploadProgress .cancel {
  cursor: pointer;
  background: none;
  border: none;
  -webkit-appearance: none;
}

.uploadProgress .progress {
  border: 1px solid #D5E1FF;
  display: flex;
  flex-basis: 13px;
}

.uploadProgress .progress::before {
  content: '';
  display: block;
  flex-grow: 1;
  background-color:  #2444a3;
}

.quotationPopup-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;
}
.quotationPopup-header h2{
  font-size: 1.125rem;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  color: var(--primary-color);
  margin: 0 15px 0 0;
}
.quotationPopup-container{
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  overflow: hidden;
  border-top: 1px #E2E9F0 solid;
  padding: 40px 0 0 0;
}

.quotationPopup-partsContainer{
  width: 55vw;
  margin: 0 3vw 0 0;
  
  display: flex;
  flex-direction: column;
}

.quotationPopup-informationContainer{
  width: 25vw;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 0 10px 10px 0;
}

.quotationPopup-informationContainer-accountContainer{
  margin: 0 0 20px 0;
}

.quotationPopup-informationContainer h2,
.quotationPopup-partsContainer h2{
  font-family: 'Poppins', sans-serif;
  font-size: 1.125rem;
  color: var(--primary-color);
  margin: 0 0 7px 0;
}

.quotationPopup-informationContainer .horizontalLine,
.quotationPopup-partsContainer .horizontalLine{
  width: 100%;
  height: 1px;
  background-color: #E2E9F0;
  margin: 0 0 15px 0;
}

.quotationPopup-informationContainer-calculationContainer .horizontalLine{
  margin: 8px 0 8px 0;
}

.fullWidth input{
  width: 100%;
  height: 32px;
}

.smallWidth {
  display: flex;
}

.smallWidth select{
  flex: 1 1;
  max-width: 50%;
}

.smallWidth select:not(:first-child) {
  margin-left: 20px;
}

.accountInformationContainer{
  width: 100%;
  font-size: 0.875rem;
}
.accountInformationContainer-rowContainer{
  display: flex;
  flex: row 1;
}
.accountInformationContainer-information{
  font-weight: 700;
  min-width: 70px;
}
.accountInformationContainer-data{
  margin: 0 0 0 10px;
  width: 240px;
}

.quotationPopup-informationContainer-shippingContainer-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.quotationPopup-informationContainer-calculationContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 20px 0 0 0;
}
.calculationContainer-information{
  font-weight: 700;
  font-size: 1.125rem;
  font-family: 'Poppins', sans-serif;
}
.calculationContainer-data {
  position: relative;
  font-size: 0.875rem;
  display: inline-block;
  width: 100px;
  margin: 0 0 0 20px;
}

.calculationContainer-data .inputPrefix {
  position: absolute;

  font-size: 0.875rem;
  font-family: 'Karla', sans-serif;
  color: var(--primary-color);
  padding: 3px 0 0;
}

.calculationContainer-data input {
  width: 100%;
  font-size: 0.875rem;
  font-family: 'Karla', sans-serif;
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  padding: 2px 0;
  margin: 0 0 0 -5px;
}

.calculationContainer-data .inputPrefix + input {
  padding-left: 17px;
}

.quotationPopup-partsContainer-headerContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px 0 0;
}

.quotationPopup-partsContainer-parts{
  width: 65%;
  display: flex;
  flex-direction: row;
}
.quotationPopup-partsContainer-price,
.quotationPopup-partsContainer-amount,
.quotationPopup-partsContainer-total{
  width: 10%;
}
.quotationPopup-partsContainer-amount{
  text-align: center;
}
.quotationPopup-partsContainer-total{
  text-align: end;
}
.quotationPopup-partListContainer{
  overflow-y: scroll;
  box-shadow: inset 0 -10px 10px -10px rgba(0,0,0,0.1);
  flex: 1 1;
  padding: 0 10px 0 0;
}

.calculationContainer-buttonContainer-desktop,
.calculationContainer-buttonContainer-mobile{
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0 0;
}
.calculationContainer-buttonContainer-desktop button:not(:first-child),
.calculationContainer-buttonContainer-mobile button:not(:first-child){
  margin: 0 0 0 27px;
}

.calculationContainer-buttonContainer-mobile{
  display: none;
}


#delete-btn{
  border: none;
  background: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#delete-btn:hover {
  background-color: #E2E9F0;
}
#delete-btn:hover .delete-btn-svg-1{
  fill: var(--secondary-color);
}
#delete-btn-svg{
  height: 18px;
}
.delete-btn-svg-1{
  fill: #2444a3;
}
.informationPopupContainer{
  position: relative;
}
.informationPopupContainer-button{
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(5px, 5px);
          transform: translate(5px, 5px);
  cursor: pointer;
  position: relative;
  
}
#informationButton{
  width: 10px;
  height: 10px;
}

.manufacturingInformationContainer{
  position: absolute;
  bottom: 15px;
  right: 0;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  box-shadow: 3px 3px 3px rgba(0,0,0,0.03);
  padding: 20px;
  background-color: #fff;
}
.manufacturingInformationContainer .row{
  display: flex;
  flex-direction: row;
}
.manufacturingInformationContainer :not(:last-child){
  margin-bottom: 9px;
}
.manufacturingInformationContainer :not(:first-child){
  margin-top: 9px;
}
.manufacturingInformationContainer .property{
  min-width: 200px;
  margin: 0;
}
.manufacturingInformationContainer .value{
  min-width: 80px;
  margin: 0;
}
.manufacturingInformationContainer .total{
  font-weight: 700;
}

@media only screen and (max-width: 1279px) {
  .quotationPopup-container {
    flex-direction: column;
    justify-content: flex-start;
  }

  .quotationPopup-partsContainer{
    width: 100%;
  }
  .quotationPopup-informationContainer{
    width: 80vw;
  }

  .quotationPopup-informationContainer-accountContainer,
  .quotationPopup-informationContainer-shippingContainer,
  .quotationPopup-informationContainer-calculationContainer{
    width: 25%;
  }

  .quotationPopup-informationContainer{
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 50px 0;
  }

  .quotationPopup-informationContainer-shippingContainer-row{
    flex-direction: column;
  }
  .popup-inputContainer{
    margin: 0;
  }
  .smallWidth select{
    width: 150px;
    margin: 0 0 19px 0;
  }
  .quotationPopup-informationContainer-calculationContainer{
    margin: 30px 30px 0 0;
  }
  .accountInformationContainer{
    margin: 19px 0 0 0;
  }
  .calculationContainer-buttonContainer-mobile{
    display: flex;
  }
  .calculationContainer-buttonContainer-desktop{
    display: none;
  }
}
.quotationPopup-partsContainer-parts h4{
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--primary-color);
  margin: 0;
}
.quotationPopup-partsContainer-imageContainer{
  margin: 0 20px 0 0;
  height: 100%;
  display: flex;
  align-items: center;
}
.quotationPopup-partsContainer-imageContainer img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.quotationPopup-partsContainer-informationContainer{
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
}
.quotationPopup-partsContainer-general,
.quotationPopup-partsContainer-operations,
.quotationPopup-partsContainer-quality{
  width: 33%;
}


.quotationPopup-partsContainer-informationNoteContainer{
  width: 100%;
}
.quotationPopup-partsContainer-noteContainer{
  width: 100%;
  margin: 10px 0 0 0;
}
.quotationPopup-partsContainer-noteContainer input{
  width: 100%;
  font-size: 0.875rem;
  font-family: 'Karla', sans-serif;
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  padding: 5px 11px 5px 11px;
}
.quotationPopup-part-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 5px 0;
}
.quotationPopup-part-header h3{
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  color: var(--primary-color);
}
.quotationPopup-part-main{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 30px 0;
}
.quotationPopup-part-moreTopMargin{
  margin: 20px 0 0 0;
}

.quotationPopup-partsContainer-amount input{
  width: 50%;
  font-size: 0.875rem;
  font-family: 'Karla', sans-serif;
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  padding: 2px;
  margin: 0 5px 0 0;
  text-align: center;
}
/**
 * To make use of a table please specify grid-template-columns 
 * for .wcTable in your container for widths over 640px.
 *
 * Specify grid order based on responsiveness as needed.
 *
 * Minimal example:

.userManagementPage .wcTable {
  grid-template-columns: repeat(5, auto);
}

@media only screen and (max-width: 640px) {
  .userManagementPage .dataProperty-client {
    grid-column: 1 / 3;
  }
}

**/

.wcTable .dataProperty-actions {
  display: flex;
  justify-content: flex-end;
}

.wcTable .dataProperty-actions>* {
  display: contents;
}

.wcTable .dataProperty-actions .tableAction {
  margin-left: 8px;
  cursor: pointer;
  margin: -7px 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 641px) {
  .wcTable {
    display: grid;
  }

  .dataHeader {
    display: contents;
    font-weight: 700;
    font-size: 1rem;
  }

  .dataHeader>* {
    margin-bottom: 4px;
  }

  .dataHeader>.sortable {
    cursor: pointer;
  }

  .dataEntry {
    display: contents;
    font-size: 0.875rem;
  }

  .dataEntry.selectable {
    cursor: pointer;
  }

  .dataEntry>* {
    background-color: #FBFBFB;
    margin-top: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dataEntry.selected>* {
    background-color: var(--secondary-color-light);
  }

  .dataHeader>*:first-child,
  .dataEntry>*:first-child {
    padding-left: 20px;
    border-radius: 10px 0 0 10px;
  }

  .dataHeader>*:last-child,
  .dataEntry>*:last-child {
    padding-right: 20px;
    border-radius: 0 10px 10px 0;
  }

  .dataProperty {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 5px;
  }
}

@media only screen and (max-width: 640px) {
  .dataHeader {
    display: none;
  }

  .dataEntry {
    margin-top: 8px;
    padding: 10px 20px 10px 20px;
    background-color: #FBFBFB;
    border-radius: 10px;

    display: grid;
    grid-template-columns: repeat(2, auto);
  }

  .wcTable .dataProperty-actions {
    display: none;
  }
}
.calculationOverview-container {
  display: flex;
  overflow: hidden;
}

.calculationOverview-container h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.125rem;
  color: var(--primary-color);
  margin: 0 0 7px 0;
}

.calculationOverview-container .border {
  position: relative;
  border: 1px solid #E2E9F0;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.calculationOverview-container .specification {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  gap: 10px;
}

.calculationOverview-container .specification .total {
  font-weight: bold;
}

.calculationOverview-container .divider {
  grid-column: 1 / 3;
  width: 100%;
  height: 1px;
  background-color: #E2E9F0;
}

.calculationOverview-container .nestingPreview {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.calculationOverview-container .priceSpecification {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
}

.calculationOverview-container .preview {
  flex-grow: 1;
  overflow: hidden;
}

.calculationOverview-container .preview img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.calculationOverview-container .preview .loadingContainer {
  width: 100%;
  height: 100%;
}

.calculationOverview-container .plates {
  width: 55vw;
  flex-basis: 20vh;
  flex-shrink: 0;
  overflow: scroll;
}

.calculationOverview-container .plates .wcTable {
  grid-template-columns: repeat(6, auto);
}

.genericPage {
  width: 100vw;
  padding: 0 calc( (100vw - 1386px) / 2 ) 37px calc( (100vw - 1386px) / 2 );
  min-height: 100vh;
  background-color: #FBFBFB;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.genericPage-title {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-top: 23px;
}

.genericPage-mainContainer {
  font-family: 'Karla', sans-serif;
  flex: 1 1;
  width: 1386px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #E2E9F0;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03);
  padding: 18px 118px 18px 118px;
}

.genericPage-buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 0 28px 0;
}

.genericPage-buttonContainer > * {
  display: contents;
}

.genericPage-buttonContainer button {
  font-family: 'Karla', sans-serif;
  color: #FBFBFB;
  background-color: var(--secondary-color);
  border: none;
  border-radius: 4px;
  font-weight: 700;
  font-size: 0.75rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

@media only screen and (max-width: 1476px) {

  .genericPage {
    padding: 0 45px 37px 45px;
  }
  
  .genericPage-mainContainer {
    width: calc(100vw - 90px);
  }

}

@media only screen and (max-width: 1280px) {

  .genericPage-mainContainer {
    padding: 18px 20px 18px 20px;
  }
}

@media only screen and (max-width: 960px) {

  .genericPage {
    padding: 0 29px 37px 29px;
  }
  
  .genericPage-mainContainer {
    width: calc(100vw - 58px);
    padding: 18px 17px 18px 17px;
  }
}

@media only screen and (max-width: 640px) {

  .genericPage-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary-color);
  }

  .genericPage-mainContainer {
    padding: 24px 24px 24px 24px;
  }

  .genericPage-buttonContainer {
    margin: 0 0 16px 0;
  }
}

@media only screen and (max-width: 400px) {

  .genericPage {
    padding: 0 0 37px 0;
  }

  .genericPage-title {
    margin-left: 22px;
  }

  .genericPage-mainContainer {
    width: 100vw;
    border-radius: 0px;
    padding: 24px 0 24px 0;
  }

  .genericPage-buttonContainer {
    padding: 0 45px 0 45px;
  }
}

.genericPage-filterButton {
  padding: 10px 20px 10px 20px;
}

.genericPage-filterButton svg {
  width: 23px;
  margin: 0 14px 0 0;
}

.genericPage-filterButton .svgFilterButton{
  fill: #fff;
  fill-rule:evenodd;
}

.quoteManagementPage .wcTable {
  grid-template-columns: repeat(8, auto);
}

@media only screen and (max-width: 640px) {
  .quoteManagementPage .dataProperty-number {
    grid-row: 1;
    grid-column: 1;
    font-weight: bold;
  }

  .quoteManagementPage .dataProperty-price {
    grid-row: 1;
    grid-column: 2;
    font-weight: bold;
  }

  .quoteManagementPage .dataProperty-createdAt {
    grid-row: 2;
    grid-column: 1;
  }

  .quoteManagementPage .dataProperty-organization {
    grid-row: 2;
    grid-column: 2;
  }

  .quoteManagementPage .dataProperty-title {
    grid-row: 3;
    grid-column: 1;
  }

  .quoteManagementPage .dataProperty-status {
    grid-row: 3;
    grid-column: 2;
  }
}

.quoteStateSelector {
  width: 112px;
  padding: 5px 13px;
  margin: -5px 0;

  background-color: var(--secondary-color-light);
  border-radius: 12px;

  display: flex;
  position: relative;
  align-items: baseline;
}

.quoteStateSelector span {
  flex-grow: 1;
  text-align: center;
}

.quoteStateSelector svg {
  flex-grow: 0;
}

.quoteStateSelector select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}

.quoteStateSelector svg path {
  fill: var(--primary-color);
}

.quoteStateSelector.red {
  background-color: #E12626;
  color: #fff;
}

.quoteStateSelector.green {
  background-color: #0CAA1B;
  color: #fff;
}

.quoteStateSelector.red svg path,
.quoteStateSelector.green svg path {
  fill: #fff;
}

@media only screen and (max-width: 640px) {
  .quoteStateSelector {
    width: 100%;
    height: 100%;
    padding: 5px 13px;
    margin: 0;

    align-items: center;
  }
}
.filterPopup .popup-section {
  display: grid;
  max-width: 60vw;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-column-gap: 20px;
}

.popup .filterPopup .popup-column {
  margin-left: 0;
}
.genericPage-addButton {
  padding: 10px 20px 10px 20px;
}

.genericPage-addButton svg {
  width: 16px;
  margin: 0 8px 0 0;
}

.genericPage-addButton .svgAddButton {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-width: 2;
}

.contactManagementPage .wcTable {
  grid-template-columns: auto 1fr repeat(3, 1fr);
}

@media only screen and (max-width: 640px) {
  .contactManagementPage .dataProperty-active {
    display: none;
  }
  .contactManagementPage .dataProperty-account {
    grid-column: 1 / 3;
    font-size: 1rem;
    font-weight: 700;
  }
}
.accountManagementPage .wcTable {
  grid-template-columns: auto 1fr auto;
}

@media only screen and (max-width: 640px) {
  .accountManagementPage .dataProperty-active {
    display: none;
  }
}

body .tenantDnsPopup .wcTable {
  margin: 30px 0;
  grid-template-columns: repeat(3, auto);
}

.tenantManagementPage .wcTable {
  grid-template-columns: auto 1fr auto;
}

@media only screen and (max-width: 640px) {
  .tenantManagementPage .dataProperty-active {
    display: none;
  }
}

