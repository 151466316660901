/**
 * To make use of a table please specify grid-template-columns 
 * for .wcTable in your container for widths over 640px.
 *
 * Specify grid order based on responsiveness as needed.
 *
 * Minimal example:

.userManagementPage .wcTable {
  grid-template-columns: repeat(5, auto);
}

@media only screen and (max-width: 640px) {
  .userManagementPage .dataProperty-client {
    grid-column: 1 / 3;
  }
}

**/

.wcTable .dataProperty-actions {
  display: flex;
  justify-content: flex-end;
}

.wcTable .dataProperty-actions>* {
  display: contents;
}

.wcTable .dataProperty-actions .tableAction {
  margin-left: 8px;
  cursor: pointer;
  margin: -7px 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 641px) {
  .wcTable {
    display: grid;
  }

  .dataHeader {
    display: contents;
    font-weight: 700;
    font-size: 1rem;
  }

  .dataHeader>* {
    margin-bottom: 4px;
  }

  .dataHeader>.sortable {
    cursor: pointer;
  }

  .dataEntry {
    display: contents;
    font-size: 0.875rem;
  }

  .dataEntry.selectable {
    cursor: pointer;
  }

  .dataEntry>* {
    background-color: #FBFBFB;
    margin-top: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dataEntry.selected>* {
    background-color: var(--secondary-color-light);
  }

  .dataHeader>*:first-child,
  .dataEntry>*:first-child {
    padding-left: 20px;
    border-radius: 10px 0 0 10px;
  }

  .dataHeader>*:last-child,
  .dataEntry>*:last-child {
    padding-right: 20px;
    border-radius: 0 10px 10px 0;
  }

  .dataProperty {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 5px;
  }
}

@media only screen and (max-width: 640px) {
  .dataHeader {
    display: none;
  }

  .dataEntry {
    margin-top: 8px;
    padding: 10px 20px 10px 20px;
    background-color: #FBFBFB;
    border-radius: 10px;

    display: grid;
    grid-template-columns: repeat(2, auto);
  }

  .wcTable .dataProperty-actions {
    display: none;
  }
}