
.topNavigation ul li a:hover .svgCalculate-1,
.topNavigation ul li a:hover .svgQuotes-1,
.topNavigation ul li a:hover .svgOrders-1,
.topNavigation ul li a:hover .svgAccounts-1{
  fill: #FDFDFD;
}

.topNavigation ul li a:hover .svgCalculate-1,
.topNavigation ul li a:hover .svgQuotes-1,
.topNavigation ul li a:hover .svgOrders-1,
.topNavigation ul li a:hover .svgAccounts-1{
  fill: #FDFDFD;
}

.topNavigation ul li a:hover .svgCalculate-2,
.topNavigation ul li a:hover .svgQuotes-2,
.topNavigation ul li a:hover .svgOrders-2,
.topNavigation ul li a:hover .svgOrders-3,
.topNavigation ul li a:hover .svgAccounts-2{
  fill: var(--secondary-color);
}
.topNavigation ul li a:hover .svgQuotes-3{
  stroke: var(--secondary-color);
}
#svgCalculate,
#svgQuotes,
#svgOrders,
#svgAccounts{
  width: 34px;
  margin: 0 15px 0 0;
}

.svgCalculate-1,
.svgQuotes-1,
.svgOrders-1,
.svgAccounts-1{
  fill: var(--primary-color);
}
.svgCalculate-2,
.svgQuotes-2,
.svgOrders-2,
.svgOrders-3,
.svgAccounts-2{
  fill: #8a8ea2;
}
.svgQuotes-3{
  fill: none;
  stroke: #8a8ea2;
  stroke-linecap: round;
  stroke-width: 1.5;
}
