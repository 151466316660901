@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap');

.contactManagementPage .wcTable {
  grid-template-columns: auto 1fr repeat(3, 1fr);
}

@media only screen and (max-width: 640px) {
  .contactManagementPage .dataProperty-active {
    display: none;
  }
  .contactManagementPage .dataProperty-account {
    grid-column: 1 / 3;
    font-size: 1rem;
    font-weight: 700;
  }
}