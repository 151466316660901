@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap");

.news-newsItem {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin: 0;
  border-radius: 10px;
  border: 1px solid #e2e9f0;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03);
  min-height: 600px;
}

.news-newsItem .fileUpload {
  border: none;
  border-radius: 10px 10px 0 0;
  height: 280px;
  width: 100%;
  background-size: cover;
  background-position: center;
}
.news-newsItem .standardFileUpload {
  display: none;
}
.news-newsItem .overlay {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-newsItem .uploadText {
  text-align: center;
}
.news-newsItemDivider {
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  background-color: var(--primary-color);
  font-family: "Karla", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  display: flex;
  padding: 0 12px 0 12px;
  justify-content: space-between;
  align-items: center;
}
.news-newsItemDivider .news-newsItemAuthor {
  color: #ffffff;
  background-color: transparent;
  border: none;
}
.news-newsItemDivider .news-newsItemDate {
  color: #ffffff;
  opacity: 0.5;
  background-color: transparent;
  border: none;
  text-align: end;
}

.news-newsItem .standardButton {
  position: absolute;
  left: calc(100% - 40px);
  background: none;
  border: none;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-newsItem .standardButton svg {
  width: 50%;
}
.news-newsItem .editButton {
  top: 10px;
  background-color: var(--primary-color);
}
.news-newsItem .saveButton {
  top: 50px;
  background-color: var(--secondary-color);
}
.news-newsItem .cancelButton {
  top: 10px;
  background-color: var(--secondary-color);
}
.news-newsItem .deleteButton {
  background-color: red;
  top: 90px;
}

.news-newsItem .news-newsItemTitle,
.news-newsItem .news-newsItemTitle:disabled {
  font-family: "Karla", sans-serif;
  resize: none;
  color: var(--primary-color);
  margin: 18px 20px 15px 16px;
  font-size: 1.875rem;
  font-weight: 700;
  background-color: transparent;
  border: none;
  overflow-y: hidden;
}
.news-newsItem .news-newsItemExcerpt,
.news-newsItem .news-newsItemExcerpt:disabled {
  font-family: "Karla", sans-serif;
  resize: none;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0 20px 26px 16px;
  background-color: transparent;
  border: none;
  overflow-y: hidden;
}

@media only screen and (max-width: 960px) {
  .news-newsItem img {
    height: 242px;
  }
  .news-newsItemDivider {
    height: 42px;
    padding: 0 10px 0 10px;
  }
  .news-newsItem .news-newsItemTitle {
    font-size: 1.25rem;
    margin: 16px 17px 15px 14px;
  }
  .news-newsItem .news-newsItemExcerpt {
    margin: 0 17px 16px 14px;
  }
}

@media only screen and (max-width: 640px) {
  .news-newsItem img {
    height: 182px;
  }
  .news-newsItemDivider {
    height: 31px;
    padding: 0 8px 0 8px;
  }
  .news-newsItem .news-newsItemTitle {
    font-size: 1.125rem;
    margin: 8px 13px 7px 10px;
  }
  .news-newsItem .news-newsItemExcerpt {
    margin: 0 13px 14px 10px;
  }
}

@media only screen and (max-width: 332px) {
  .news-newsItem {
    width: 100%;
    min-width: 100%;
  }
  .news-newsItem img {
    height: 174px;
  }
  .news-newsItemDivider {
    height: 30px;
    padding: 0 7px 0 7px;
    font-size: 0.75rem;
  }
  .news-newsItem .news-newsItemTitle {
    font-size: 1.125rem;
    margin: 8px 12px 9px 10px;
  }
  .news-newsItem .news-newsItemExcerpt {
    margin: 0 12px 20px 10px;
    font-size: 0.75rem;
  }
}
