@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap');

.listSubItemContainer{
  display: flex;
  flex-direction: row;
}
.listSubItem{
  padding: 0 5px 0 5px;
}
.listSubItem-warning{
  width: 20px;
  padding-top: 6px;
 
}

.listSubItem-warning svg{
  transform: translate(-5px, 0);
}

.listSubItem-warningBox{
  border: 1px #E2E9F0 solid;
  border-radius: 10px;
  margin-bottom: 5px;
}

.listSubItem .listSubItem-header{
  padding: 4px 0;
  font-family: 'Karla', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  color: #181F47;
  display: flex;
  align-items: center;
}

.listSubItem .listSubItem-header .listItem-header-itemName{
  margin-left: 10px;
  font-family: 'Karla', sans-serif;
}


.listSubItem .listSubItem-main{
  padding: 15px 30px 15px 0; 
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.listSubItem .listSubItem-main .listSubItem-image{
  width: auto;
  margin-right: 10px;
  height: 90px;
  cursor: zoom-in;
}

.listSubItem .listSubItem-main .listSubItem-main-informationContainer{
  line-height: 1.3;
  display: flex;
}

.listSubItem .listSubItem-main .listSubItem-main-informationContainer .columnLeft{
  width: 45%;
  min-width: 110px;
  font-size: 0.6875rem;
  color: #181F47;
}

.listSubItem .listSubItem-main .listSubItem-main-informationContainer .columnLeft .property,
.listSubItem .listSubItem-main .listSubItem-main-informationContainer .columnRight .property{
  font-family: 'Karla', sans-serif;
  font-weight: 700;
  
}

.listSubItem .listSubItem-main .listSubItem-main-informationContainer .columnRight{
  width: 55%;
  font-size: 0.6875rem;
  color: #181F47;
  display: flex;
  min-width: 135px;
}

.listSubItem .listSubItem-main .listSubItem-main-informationContainer .columnRight .operationsContainer{
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.listSubItem .listSubItem-main .listSubItem-main-informationContainer .columnRight .operationsContainer span{
  display: block;
}