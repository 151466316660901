.userHover {
  width: 100%;
  background-color: #fff;
  display: none;
  position: absolute;
  top: 75px;
  z-index: 1;
  border-radius: 0 0 10px 10px;
  box-shadow: 3px 3px 25px rgb(0 0 0 / 7%);
  flex-direction: column;
  overflow: hidden;
}

.userHover .action:link,
.userHover .action:visited {
  color: #333;
  padding: 16px 24px;
  width: 100%;
  text-align: center;
  margin: 0;
  text-decoration: none;
}

.userHover .action:link:hover,
.userHover .action:visited:hover {
  background-color: var(--secondary-color);
  color: #fff;
}
