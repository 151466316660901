.contactPopup .contactPopup-inputZipCode {
  width: 80px;
  margin-right: 16px;
}
.contactPopup .contactPopup-inputEmail{
  max-width: 300px;
  width: 300px;
  min-width: 100%;
}
.contactPopup .popup-checkboxInput:not(:last-child){
  margin-right: 20px;
}

@media only screen and (max-width: 690px) {
  .contactPopup .popup-claimsContainer,
  .contactPopup .popup-documentsContainer{
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 420px) {
  .contactPopup .contactPopup-inputZipCode {
    width: 80px !important;
  }
}

@media only screen and (max-width: 370px) {
  .contactPopup .contactPopup-inputZipCode {
    width: 80px !important;
  }
}
