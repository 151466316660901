@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap');

.listItem{
  padding: 0 20px 0 15px;
}

.listItem .listItem-header{
  padding: 12px 0;
  border-bottom: 1px solid #E2E9F0;
  font-family: 'Karla', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  color: #181F47;
  display: flex;
  align-items: center;
}

.listItem .listItem-header .listItem-header-itemName{
  margin-left: 10px;
  font-family: 'Poppins', sans-serif;
}


.listItem .listItem-main{
  padding: 15px 30px 15px 0; 
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.listItem .listItem-main.valid {
  background-color: rgba(38, 225, 38, 0.25);
}

.listItem .listItem-main.invalid {
  background-color: rgba(225, 38, 38, 0.25);
}

.listItem .listItem-main .listItem-image {
  width: auto;
  margin-right: 10px;
  height: 90px;
  cursor: zoom-in;
}

.listItem .listItem-main .listItem-main-informationContainer{
  line-height: 1.3;
  display: flex;
}

.listItem .listItem-main .listItem-main-informationContainer .columnLeft{
  width: 45%;
  min-width: 110px;
  font-size: 0.6875rem;
  color: #181F47;
}

.listItem .listItem-main .listItem-main-informationContainer .columnLeft .property,
.listItem .listItem-main .listItem-main-informationContainer .columnRight .property{
  font-family: 'Karla', sans-serif;
  font-weight: 700;
    
  
}

.listItem .listItem-main .listItem-main-informationContainer .columnRight{
  width: 55%;
  font-size: 0.6875rem;
  color: #181F47;
  display: flex;
  min-width: 135px;
}

.listItem .listItem-main .listItem-main-informationContainer .columnRight .operationsContainer{
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.listItem .listItem-main .listItem-main-informationContainer .columnRight .operationsContainer span{
  display: block;
}