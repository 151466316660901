@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap');


.news{
    color: var(--primary-color);
    font-family: 'Karla', sans-serif;
    width: 1386px;
    position: relative;
}
.news-title{
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
}
.news-horizontalLine{
    width: 100%;
    height: 1px;
    background-color: #E2E9F0 ;
    margin: 11px 0 21px 0;
}
.news-newsItemsContainer{
    width: 100%;
}
.news .slick-list {
    margin: 0 -15px;
}

.news .slick-slide {
    padding: 0 15px;
}

@media only screen and (max-width: 1476px) {
    .news{
      width: calc(100vw - 90px);
    }
}
@media only screen and (max-width: 960px) {
    .news-newsItemsContainer{
        margin: 0 38px 0 38px;
        width: calc( 100% - 38px - 38px );
    }
}


@media only screen and (max-width: 640px) {
    .news{
      width: calc(100vw - 44px);
    }
    .news-newsItemsContainer{
        margin: 0 0 0 0;
        width: 100%;
    }
}