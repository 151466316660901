.quoteStateSelector {
  width: 112px;
  padding: 5px 13px;
  margin: -5px 0;

  background-color: var(--secondary-color-light);
  border-radius: 12px;

  display: flex;
  position: relative;
  align-items: baseline;
}

.quoteStateSelector span {
  flex-grow: 1;
  text-align: center;
}

.quoteStateSelector svg {
  flex-grow: 0;
}

.quoteStateSelector select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}

.quoteStateSelector svg path {
  fill: var(--primary-color);
}

.quoteStateSelector.red {
  background-color: #E12626;
  color: #fff;
}

.quoteStateSelector.green {
  background-color: #0CAA1B;
  color: #fff;
}

.quoteStateSelector.red svg path,
.quoteStateSelector.green svg path {
  fill: #fff;
}

@media only screen and (max-width: 640px) {
  .quoteStateSelector {
    width: 100%;
    height: 100%;
    padding: 5px 13px;
    margin: 0;

    align-items: center;
  }
}