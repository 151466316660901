.quotationPopup-partsContainer-parts h4{
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--primary-color);
  margin: 0;
}
.quotationPopup-partsContainer-imageContainer{
  margin: 0 20px 0 0;
  height: 100%;
  display: flex;
  align-items: center;
}
.quotationPopup-partsContainer-imageContainer img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.quotationPopup-partsContainer-informationContainer{
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
}
.quotationPopup-partsContainer-general,
.quotationPopup-partsContainer-operations,
.quotationPopup-partsContainer-quality{
  width: 33%;
}


.quotationPopup-partsContainer-informationNoteContainer{
  width: 100%;
}
.quotationPopup-partsContainer-noteContainer{
  width: 100%;
  margin: 10px 0 0 0;
}
.quotationPopup-partsContainer-noteContainer input{
  width: 100%;
  font-size: 0.875rem;
  font-family: 'Karla', sans-serif;
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  padding: 5px 11px 5px 11px;
}
.quotationPopup-part-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 5px 0;
}
.quotationPopup-part-header h3{
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  color: var(--primary-color);
}
.quotationPopup-part-main{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 30px 0;
}
.quotationPopup-part-moreTopMargin{
  margin: 20px 0 0 0;
}

.quotationPopup-partsContainer-amount input{
  width: 50%;
  font-size: 0.875rem;
  font-family: 'Karla', sans-serif;
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  padding: 2px;
  margin: 0 5px 0 0;
  text-align: center;
}