.quoteManagementPage .wcTable {
  grid-template-columns: repeat(8, auto);
}

@media only screen and (max-width: 640px) {
  .quoteManagementPage .dataProperty-number {
    grid-row: 1;
    grid-column: 1;
    font-weight: bold;
  }

  .quoteManagementPage .dataProperty-price {
    grid-row: 1;
    grid-column: 2;
    font-weight: bold;
  }

  .quoteManagementPage .dataProperty-createdAt {
    grid-row: 2;
    grid-column: 1;
  }

  .quoteManagementPage .dataProperty-organization {
    grid-row: 2;
    grid-column: 2;
  }

  .quoteManagementPage .dataProperty-title {
    grid-row: 3;
    grid-column: 1;
  }

  .quoteManagementPage .dataProperty-status {
    grid-row: 3;
    grid-column: 2;
  }
}
