@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap");

.statistic {
  box-sizing: border-box;
  min-width: 200px;
  width: 200px;
  height: 226px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #e2e9f0, 3px 3px 3px rgba(0, 0, 0, 0.03);
  padding: 20px 20px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.statistic:hover {
  box-shadow: 0 0 0 2px var(--secondary-color), 3px 3px 3px rgba(0, 0, 0, 0.03);
}

.statistic-icon {
  width: 25px;
  align-self: flex-start;
}
.statistic-statistic {
  color: var(--secondary-color);
  font-family: "Poppins", sans-serif;
  font-size: 1.875rem;
  font-weight: 700;
}
.statistic-subtitle {
  font-size: 0.625rem;
  color: var(--primary-color);
  margin: 5px 0 0 0;
  flex: 1;
}
.statistic-subtitleAmount {
  font-weight: 700;
}
.statistic-worth-container {
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 10px 0;
}
.statistic-worth {
  font-size: 0.625rem;
}
.statistic-worth-amount {
  font-size: 0.875rem;
  font-weight: 700;
}
.statistic-horizontalLine {
  width: 100%;
  height: 1px;
  background-color: #e2e9f0;
  margin: 0 0 10px 0;
}
.statistic-unit,
.statistic-difference-container {
  color: var(--primary-color);
  font-family: "Karla", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: capitalize;
}
.statistic-difference {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin: 0 10px 0 10px;
}

.statistic-difference-container svg {
  width: 0.5rem;
  margin: 0.1875rem 0 0.1875rem 0;
}

@media only screen and (max-width: 450px) {
  .statistic {
    min-width: 130px;
    width: 130px;
    height: auto;
  }
}
