.addNewNewsItem{
  position: relative;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    margin: 0;
    border-radius: 10px;
    border: 1px solid #E2E9F0;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03);
    min-height: 600px;
}

.addNewItem{
  border: none;
  display: flex;
  background-color: var(--primary-color);
  color: white;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 10px;
  transition: background-color 500ms ease;
  flex-direction: column;
  font-family: 'Karla', sans-serif;
  cursor: pointer;
}
.addButton{
  width: 50px;
}

.addNewItem:hover{
  background-color: var(--secondary-color);
}

@media only screen and (max-width: 332px) {
  .addNewNewsItem{
      width: 100%;
      min-width: 100%;
  }
}