@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap');

.chart{
  box-sizing: border-box;
  min-width: 390px;
  width: 390px;
  height: 226px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #e2e9f0, 3px 3px 3px rgba(0, 0, 0, 0.03);
  padding: 12px 30px 30px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart:hover{
  box-shadow: 0 0 0 2px var(--secondary-color), 3px 3px 3px rgba(0, 0, 0, 0.03);
}

.chartTitle{
  font-size: 0.75rem;
  font-family: 'Poppins', sans-serif;
  align-self: flex-start;
  margin: 0 0 5px 0;
}
