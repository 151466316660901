@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap');

.checkboxContainer{
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 22px;
}
.checkboxContainer input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark{
  position: absolute;
  top: calc(50% - 7px);
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 2px var(--primary-color) solid;
  border-radius: 2px;
}

.checkboxContainer input:checked ~ .checkmark {
  background-color: var(--primary-color);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

.checkboxContainer .checkmark:after {
  left: calc(50% - 2.5px);
  top: 0;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}