
.uploadPopup {
  width: 920px;
  max-width: 100%;
  display: flex;
  overflow-y: hidden;
}

.uploadPopup .popup-column {
  align-items: stretch;
}

.uploadPopup .progressContainer {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropzone {
  display: block;
  text-align: center;
  position: relative;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23E2E9F0' stroke-width='1' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 20px;
  padding: 30px;
  margin-top: 20px;
}

.dropzone::after {
  content: attr(data-label);
  font-size: 24px;
  font-weight: bold;
  font-family: 'Poppins';
  text-transform: uppercase;
  color: var(--primary-color);
}

.dropzone input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.dropzone input:drop {
  opacity: 1;
}
