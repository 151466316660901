@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap');

.statistics{
    color: var(--primary-color);
    font-family: 'Karla', sans-serif;
    width: 1386px; 
}
.statistics-title{
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
}
.statistics-horizontalLine{
    width: 100%;
    height: 1px;
    background-color: #E2E9F0 ;
    margin: 11px 0 24px 0;
}

.statistics-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media only screen and (max-width: 1476px) {
    .statistics{
      width: calc(100vw - 90px);
    }
}
@media only screen and (max-width: 1300px) {
    .statistics-container{
        margin: 0 -5px;
        justify-content: center;
    }
    .statistic{
        margin: 0 5px;
    }
    .chart{
        margin: 24px 0 0 0;
    }

}

@media only screen and (max-width: 925px) {
    .statistic{
        margin: 0 5px 24px 5px;
    }
    .chart{
        margin: 0 5px;
        width: 410px;
    }
}

@media only screen and (max-width: 640px) {
    .statistics{
      width: calc(100vw - 44px);
    }
}

@media only screen and (max-width: 450px) {
    .chart{
      min-width: 270px;
      width: 270px;
      height: auto;
      padding: 12px 20px 34px 20px;
    }
  }