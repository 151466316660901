@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap');

.calculation{
  font-family: 'Karla', sans-serif;
  width: 100vw;
  padding: 30px calc( (100vw - 1386px) / 2 ) 37px calc( (100vw - 1386px) / 2 );
  min-height: 100vh;
  background-color: #FBFBFB;
  display: flex;
  flex-direction: row;
}

.partsListContainer{
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.partPreviewContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.partPreviewContainer-title, .partsListContainer-title{
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
  font-family: 'Poppins', sans-serif;
  margin-bottom: 8px;
}

@media only screen and (max-width: 1476px) {
  .calculation {
    padding: 30px 45px 37px 45px;
  }
}