@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap');

.footer{
  width: 100%;
  flex-basis: 75px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: var(--primary-color);
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 316px 0 267px;
  z-index: 2;
}
.footer .copyrightSpan{
  font-family: 'Karla', sans-serif;
  font-size: 0.875rem;
}
.footer-footerNavigation ul{
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  display: flex;
  flex-direction: row;
}
.footer-footerNavigation ul li{
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  margin: 0 0 0 48px;
}

.footer-footerNavigation ul li a{
  color: #FFFFFF;
  text-decoration: none;
  font-family: 'Karla', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
}


@media only screen and (max-width: 1280px) {

  .footer{
    justify-content: flex-end;
    padding: 0 34px 0 0;
  }
  .footer .copyrightSpan{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

@media only screen and (max-width: 960px) {
  .footer .copyrightSpan,
  .footer-footerNavigation ul li a{
    font-size: 0.75rem;
  }
  .footer-footerNavigation ul li{
    margin: 0 0 0 20px;
  }
}

@media only screen and (max-width: 710px) {
  .footer{
    justify-content: space-between;
    padding: 0 22px 0 25px;
  }
  .footer .copyrightSpan{
    position: inherit;
    left: 0;
    transform: translate(0, 0);
  }

  .footer-footerNavigation ul li a{
    font-size: 0.625rem;
  }
  .footer-footerNavigation ul li{
    margin: 0 0 0 15px;
  }

}

@media only screen and (max-width: 400px) {
  .footer{
    flex-direction: column-reverse;
    padding: 16px 0 15px 0;
  }
  .footer-footerNavigation ul li a{
    font-size: 0.75rem;
  }
  .footer-footerNavigation ul li{
    margin: 0 0 0 0;
  }
  .footer-footerNavigation ul{
    width: 259px;
    justify-content: space-between;
  }
}
