/**
 * We know this stylesheet will always be included.
 * And since we want to avoid inline styles to prevent injection,
 * we'll just put the global styles here.
 **/

html, body {
  margin: 0;
  border: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-pageContainer {
  flex-grow: 1;
  display: flex;
  position: relative;
}

.pageContainer {
  flex-grow: 1;
  display: flex;
  width: 100%;
}

.pageContainer > div {
  flex-grow: 1;
  width: 100%;
}
