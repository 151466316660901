.authenticationPage {
  background-image: url('./authentication-background.png');
  background-size: cover;
  background-position: center;
}

.authenticationPage .popup-popupButton {
  min-width: 135px;
}

.authenticationPage .popup-popupTitle {
  margin: 18px 0 12px 0;
}

.authenticationPage .popup-container {
  padding-left: 175px;
  padding-right: 175px;
}

.authenticationPage .popup-inputContainer input {
  width: 300px;
}

.authenticationPage .popup-linkContainer a { 
  color: #2444A3;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

@media only screen and (max-width: 760px) {
  .authenticationPage .popup-popupTitle{
    width: 300px;
    margin: 18px auto 12px auto;
  }
  .authenticationPage .popup-container {
    padding-left: 0;
    padding-right: 0;
    width: 85vw;

  }

}

@media only screen and (max-width: 420px) {
  .authenticationPage .popup-popupTitle{
    width: 250px;
  }
  .authenticationPage form{
    width: 250px;
  }
  .authenticationPage .popup-inputContainer input{
    width: 250px;
  }
  .authenticationPage .popup-linkContainer a{
    margin: 0;
  }
}

@media only screen and (max-width: 370px) {
  .authenticationPage .popup-popupTitle{
    width: 200px;
  }
  .authenticationPage form{
    width: 200px;
  }
  .authenticationPage .popup-inputContainer input{
    width: 200px;
  }
  .authenticationPage .popup-linkContainer a{
    margin: 0;
  }
}