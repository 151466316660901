@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap');

.genericPage {
  width: 100vw;
  padding: 0 calc( (100vw - 1386px) / 2 ) 37px calc( (100vw - 1386px) / 2 );
  min-height: 100vh;
  background-color: #FBFBFB;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.genericPage-title {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-top: 23px;
}

.genericPage-mainContainer {
  font-family: 'Karla', sans-serif;
  flex: 1;
  width: 1386px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #E2E9F0;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03);
  padding: 18px 118px 18px 118px;
}

.genericPage-buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 0 28px 0;
}

.genericPage-buttonContainer > * {
  display: contents;
}

.genericPage-buttonContainer button {
  font-family: 'Karla', sans-serif;
  color: #FBFBFB;
  background-color: var(--secondary-color);
  border: none;
  border-radius: 4px;
  font-weight: 700;
  font-size: 0.75rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

@media only screen and (max-width: 1476px) {

  .genericPage {
    padding: 0 45px 37px 45px;
  }
  
  .genericPage-mainContainer {
    width: calc(100vw - 90px);
  }

}

@media only screen and (max-width: 1280px) {

  .genericPage-mainContainer {
    padding: 18px 20px 18px 20px;
  }
}

@media only screen and (max-width: 960px) {

  .genericPage {
    padding: 0 29px 37px 29px;
  }
  
  .genericPage-mainContainer {
    width: calc(100vw - 58px);
    padding: 18px 17px 18px 17px;
  }
}

@media only screen and (max-width: 640px) {

  .genericPage-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary-color);
  }

  .genericPage-mainContainer {
    padding: 24px 24px 24px 24px;
  }

  .genericPage-buttonContainer {
    margin: 0 0 16px 0;
  }
}

@media only screen and (max-width: 400px) {

  .genericPage {
    padding: 0 0 37px 0;
  }

  .genericPage-title {
    margin-left: 22px;
  }

  .genericPage-mainContainer {
    width: 100vw;
    border-radius: 0px;
    padding: 24px 0 24px 0;
  }

  .genericPage-buttonContainer {
    padding: 0 45px 0 45px;
  }
}