
.genericPage-filterButton {
  padding: 10px 20px 10px 20px;
}

.genericPage-filterButton svg {
  width: 23px;
  margin: 0 14px 0 0;
}

.genericPage-filterButton .svgFilterButton{
  fill: #fff;
  fill-rule:evenodd;
}
