@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@700&display=swap');

.partsList{
  width: 796px;
  border-radius: 10px;
  position:relative;
  overflow: hidden;
  height: calc(100% - 37px);
}

.partsList .partsList-header{
  background-color: var(--primary-color);
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 16px;
}
#upload-svg{
  width: 18px;
  
}
.partsList .partFileUpload {
  display: none;
}

.partsList .partFileUpload + .generalButton {
  margin-right: 0;
}

.partsList .partFileLabel {
  margin-top: -10px;
  padding: 15px 10px 5px 10px;
  border-radius: 0 0 5px 5px;
  background-color: var(--secondary-color-light);
}

.partsList .partFileLabel:empty {
  padding: 0;
}

.generalButton{
  border: none;
  background: none;
  background-color: var(--secondary-color);
  padding: 9px 12px 9px 12px;
  display: flex;
  align-items: center;

  color: #FBFBFB;
  font-family: 'Karla', sans-serif;
  font-weight: 700;
  font-size: 0.6875rem;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  margin-right: 16px;
}
.generalButton svg{
  margin-right: 11px;
}
.partsList-container{
  border-right: 1px solid #E2E9F0;
  border-left: 1px solid #E2E9F0;
}
.partsList-container-noUpload{
  height: calc(100% - 70px);
  border: 1px solid #E2E9F0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: var(--primary-color);
  padding: 40px 0 0 30px;
}
.partsList-noUpload{
  margin: 0;
  padding: 0;
}
.partsList-controlsContainer{
  padding: 0 37px 0 31px;
  height: 50px;
  width: 58%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.partsList-checkboxContainer{
  display: flex;
  align-items: center;
}

.partsList-checkboxContainer svg{
  margin-left: 5px;
  cursor: pointer;
}
#delete-btn{
  border: none;
  background: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#delete-btn:hover {
  background-color: #E2E9F0;
}
#delete-btn:hover .delete-btn-svg-1{
  fill: var(--secondary-color);
}
#delete-btn-svg{
  height: 18px;
}
.delete-btn-svg-1{
  fill: #2444a3;
}
.partsList-mainContainer{
  
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 5px 37px 0 16px;
  max-height: 80vh;
  min-height: 650px;
}

.partsList-list{
  width: 60%;
  margin-right: 20px;
  overflow: scroll;
  margin-bottom: 20px;
  user-select: none;
}
.partsList-list::-webkit-scrollbar{
  -webkit-appearance: none;
}
.partsList-list::-webkit-scrollbar:vertical{
  width: 10px;
}
.partsList-list::-webkit-scrollbar:horizontal{
  height: 0;
}
.partsList-list::-webkit-scrollbar-thumb{
  border-radius: 3px;
  background-color: #E2E9F0;
}
.partsList-list::-webkit-scrollbar-track-piece{
  -webkit-appearance: none;
}

.partsList .partsList-manufactureSettingsContainer{
  width: 40%;
}
.partsList .partsList-manufactureSettingsContainer .title{
  font-family: 'Poppins', sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--primary-color);
  border-bottom: 1px solid #E2E9F0;
  width: 100%;
  padding: 12px 0 6px 0;
  margin: 0 0 12px 0;
}
.partsList-manufactureSettingsContainer .manufactureContainer{
  display: flex;
  flex-direction: column;
}
.partsList-manufactureSettingsContainer .manufactureContainer span{
  font-family: 'Karla', sans-serif;
  color: var(--primary-color);
  font-weight: 700;
  font-size: 0.875rem;
}

.partsList-manufactureSettingsContainer .manufactureContainer input,
.partsList-manufactureSettingsContainer .manufactureContainer select{
  width: 164px;
  height: 42px;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  background-color: transparent;
  padding: 0 17px;
  margin: 4px 0 12px 0;
  font-family: 'Karla', sans-serif;
}

.partsList-manufactureSettingsContainer .operationsContainer{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}


.partsList-manufactureSettingsContainer .operationsContainer input,
.partsList-manufactureSettingsContainer .operationsContainer select{
  width: 250px;
  height: 42px;
  border: 1px solid #E2E9F0;
  border-radius: 4px;
  background-color: transparent;
  padding: 0 17px;
  margin: 4px 0 8px 0;
  font-family: 'Karla', sans-serif;
}

.partsList-manufactureSettingsContainer .operationsContainer .checkboxContainer.disabled {
  opacity: 0.5;
}

.partsList-manufactureSettingsContainer .operationsContainer .checkboxContainer {
  filter: alpha(opacity=50);
  font-size: 0.875rem;
  font-weight: 700;
  width: 50%;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.partsList-manufactureSettingsContainer .buttonContainer{
  display: flex;
  flex-direction: column;
}


.partsList .partsList-footer{
  background-color: var(--primary-color);
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 37px;
}
