.genericPage-addButton {
  padding: 10px 20px 10px 20px;
}

.genericPage-addButton svg {
  width: 16px;
  margin: 0 8px 0 0;
}

.genericPage-addButton .svgAddButton {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-width: 2;
}
