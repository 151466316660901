.loadingContainer {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingContainer .loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid var(--secondary-color);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: loadingSpin 1s linear infinite;
}

img {
  position: relative;
  text-indent: -100%;
}

img:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.05);
}

img:after {
  content: '';
  display: block;
  position: absolute;
  top: calc(50% - 18px);
  left: calc(50% - 18px);

  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--secondary-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: loadingSpin 1s linear infinite;
}

@keyframes loadingSpin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
